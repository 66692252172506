import { useEffect, useMemo, useState } from "react";
import useWebSocket from "react-use-websocket";
import { useChartReady, useCurrentSymbol, useWsData } from "./global.hooks";
import { _globalService } from "../services/_global.service";

export const usePriceWS = () => {
  const { account_token, socket_url, server, account } = useWsData();
  const { chartIsReady } = useChartReady();
  const { symbol, symbolReady } = useCurrentSymbol();
  const url = useMemo(() => {
    if (!account_token || !socket_url) return null;
    return `${socket_url}/GT_TRADER_PRICE/?token=${account_token}`;
  }, [socket_url, account_token]);
  const [connection, setConnection] = useState<number | null>(null);

  const filterMsg = useMemo(() => {
    return {
      ws_msg_type: "WS_PUSH",
      user: account,
      server: server,
      symbols: [symbol],
      categories: [],
      connection: connection,
    };
  }, [account, connection, server, symbol]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(url, {
    onOpen: (e) => console.log("WS Open", e),
    onClose: (e) => {
      console.warn("WS Close", e);
    },
    onError: (e) => console.error("WS Close", e),
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 200,
    reconnectInterval: 2000,
  });

  useEffect(() => {
    if (filterMsg === null || connection === null) {
      return;
    }
    const filter = { ...filterMsg, connection: connection };
    sendMessage(JSON.stringify(filter));
  }, [connection, filterMsg, sendMessage]);

  useEffect(() => {
    if (lastMessage !== null) {
      const message = JSON.parse(lastMessage.data);
      if ("connection" in message) {
        setConnection(message["connection"]);
        return;
      }
      if ("ws_msg_type" in message) {
        return;
      }

      if (chartIsReady && symbolReady && symbol === message.symbol) {
        _globalService.update_currentSymbol(message);
      }
    }
  }, [chartIsReady, lastMessage, server, symbol, symbolReady]);
};
