import {
  Bar,
  ResolutionString,
} from "../../public/charting_library/charting_library";
export interface ParamAction {
  action: "init" | "change_symbol";
}

export interface InitialParams {
  symbol: string;
  socket_url: string;
  api_url: string;
  user_token: string;
  account: string;
  account_token: string;
  server: string;
  theme: "light" | "dark";
}

export interface ApiResponse {
  success: boolean;
  data: PriceData[] | Bar[] | SymbolInfo | null;

  message: string;
  orderId?: number;
}
export interface Schema {
  Tag: number;
  Name: string;
  Fullname: string;
}

export enum TradeMode {
  SYMBOL_TRADE_MODE_DISABLED = 0,
  SYMBOL_TRADE_MODE_LONGONLY = 1,
  SYMBOL_TRADE_MODE_SHORTONLY = 2,
  SYMBOL_TRADE_MODE_CLOSEONLY = 3,
  SYMBOL_TRADE_MODE_FULL = 4,
}

export interface SymbolView {
  SymbolId: number;
  Timestamp: string;
  Symbol: string;
  Main: string;
  Asset: string;
  SubAsset: string;
  Category: string;
  Path: string;
  Isin: string;
  Description: string;
  CurrencyBase: string;
  Currencyprofit: string;
  CurrencyMargin: string;
  Color: string;
  ColorBackground: string;
  Digits: number;
  Point: number;
  Multiply: number;
  TradeMode: TradeMode;
  CalcMode: number;
  TickValue: number;
  TickSize: number;
  ContractSize: number; // because of the API - sometimes its a ContactSize sometimes its a Contractsize
  Contractsize: number; // because of the API
  Popular: boolean;
  USDRate: string;
  UsdRate_inv: boolean;
  Volumemin: number;
  Volumemax: number;
  Volumestep: number; // new steps
  stopslevel: number;
}

export interface PriceStat {
  symbol: string;
  datetime: number;
  bid_high: number;
  bid_low: number;
  ask_high: number;
  ask_low: number;
  last_high: number;
  last_low: number;
  vol_high: number;
  vol_low: number;
  trade_deals: number;
  trade_volume: number;
  trade_turnover: number;
  trade_interest: number;
  trade_buy_orders: number;
  trade_buy_volume: number;
  trade_sell_orders: number;
  trade_sell_volume: number;
  trade_volume_ext: number;
  trade_buy_volume_ext: number;
  trade_sell_volume_ext: number;
  vol_high_ext: number;
  vol_low_ext: number;
  datetime_msc: number;
  price_open: number;
  price_close: number;
  price_aw: number;
  price_obsolete: number;
  price_volatility: number;
  price_theoretical: number;
  price_greeks_delta: number;
  price_greeks_theta: number;
  price_greeks_gamma: number;
  price_greeks_vega: number;
  price_greeks_rho: number;
  price_greeks_omega: number;
  price_sensitivity: number;
}
export interface HourlyData {
  "24h_volatility_perc": string;
  sentiment: SentimentData;
}
export interface SentimentData {
  sell_perc: number;
  buy_perc: number;
}
export interface TradingHours {
  dayOfWeek: number;
  symbolSessions: SymbolSession[];
}
export interface OpenHoursData {
  is_open: boolean; // Is open currently
  change_on: number | null; // Timestamp in server time, if applicable
  is_always_open: boolean; // Is always open, ignore the other two properties
}
export interface SymbolSession {
  Close: number;
  CloseHours: number;
  CloseMinutes: number;
  Open: number;
  OpenHours: number;
  OpenMinutes: number;
  Pos: number;
}

export enum RenderDirection {
  up = "up",
  down = "down",
  none = "none",
}
export interface RenderObject {
  value: string;
  classValue?: string;
  direction?: RenderDirection;
}

export interface PriceData {
  [key: string]: any; // Used to dynamically index properties
  Schema: Schema;
  symbol: string;
  dateTime: number;
  bid: number;
  ask: number;
  volume: number;
  dateTimeMSC: number;
  flags: number;
  server_id: string;
  symbolView: SymbolView;
  priceStat: PriceStat;
  totalProfit: number;
  high?: string;
  low?: string;
  hourly_data?: HourlyData;
  // Trading hours FE implementation
  trading_hours?: TradingHours[];
  // Enrhiched data
  open_hours?: OpenHoursData;
  updatedBid?: number; // Difference between old and new price
  updatedAsk?: number; // Difference between old and new price
  spread?: string;
  pl?: RenderObject;
  time?: string;
  plpoint?: string;
  symbolImageUrl?: string;
  "24change"?: RenderObject;
  "24volatility"?: RenderObject;
  // symbolInfo: SymbolInfo;
  "chart-data": {
    latest: number | null;
  };
}

export interface ChartValuesParams {
  period: ResolutionString;
  symbol: string;
  count_back: number;
  date_from: number;
  date_to: number;
  first_data_request: boolean;
}
export const getCandleResolutionRouteParam = new Map([
  ["1", "minute"],
  ["5", "5minutes"],
  ["15", "15minutes"],
  ["30", "30minutes"],
  ["60", "hour"],
  ["240", "4hours"],
  ["1D", "day"],
  ["1W", "week"],
  ["1M", "month"],
]);

export const getCandleResolutionMinutes = new Map([
  ["1", 1000 * 60],
  ["5", 1000 * 60 * 5],
  ["15", 1000 * 60 * 15],
  ["30", 1000 * 60 * 30],
  ["60", 1000 * 60 * 60],
  ["240", 1000 * 60 * 60 * 4],
  ["1D", 1000 * 60 * 60 * 24],
  ["1W", 1000 * 60 * 60 * 24 * 7],
  ["1M", 1000 * 60 * 60 * 24 * 30],
]);
export interface SymbolInfo {
  Schema: Schema;
  accruedinterest: number;
  basis: string;
  calcmode: number;
  color: string;
  colorbackground: string;
  contractsize: number;
  currencybase: string;
  currencymargin: string;
  currencyprofit: string;
  description: string;
  digits: number;
  execmode: number;
  expirflags: number;
  facevalue: number;
  fillflags: number;
  filterdiscard: number;
  filtergap: number;
  filtergapticks: number;
  filterhard: number;
  filterhardticks: number;
  filtersoft: number;
  filtersoftticks: number;
  filterspreadmax: number;
  filterspreadmin: number;
  freezelevel: number;
  gtcmode: number;
  iecheckmode: number;
  iesliplosing: number;
  ieslipprofit: number;
  ietimeout: number;
  ievolumemax: number;
  international: number;
  isin: string;
  marginflags: number;
  margininitial: number;
  marginmaintenance: number;
  mode: string;
  multiply: number;
  optionmode: number;
  orderflags: number;
  page: string;
  path: string;
  point: number;
  pricelimitmax: number;
  pricelimitmin: number;
  pricesettle: number;
  quote: TradingHours[];
  quotestimeout: number;
  reflags: number;
  retimeout: number;
  server_id: string;
  source: string;
  splicetimedays: number;
  splicetimetype: number;
  splicetype: number;
  spread: number;
  spreadbalance: number;
  spreaddiff: number;
  spreaddiffbalance: number;
  stopslevel: number;
  swap3day: number;
  swapRateFriday: number;
  swapRateMonday: number;
  swapRateSaturday: number;
  swapRateSunday: number;
  swapRateThursday: number;
  swapRateTuesday: number;
  swapRateWednesday: number;
  swaplong: number;
  swapmode: number;
  swapshort: number;
  symbolView: SymbolView;
  symbolname: string;
  tickbookdepth: number;
  tickflags: number;
  ticksize: number;
  tickvalue: number;
  timeexpiration: number;
  timestamp: number;
  timestart: number;
  trade: TradingHours[];
  tradeflags: number;
  trademode: number;
  transactiontype: string;
  volumelimit: number;
  volumemax: number;
  volumemin: number;
  volumestep: number;
  marginhedged: number;
  chart_data?: SymbolInfoChartData;
}
export interface SymbolInfoChartData {
  latest: number;
}
