import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  pairwise,
  startWith,
  Subscription,
} from "rxjs";
import { ApiGlobalService } from "../utils/api.utils";
import { PriceData } from "../models/global.models";
import { symbolsInitEnrichment } from "../helpers/priceData.helper";
import config from "../config.json";

class GlobalService {
  private searchParams = new URLSearchParams(window.location.search);
  private symbol = new BehaviorSubject<string | null>(null);
  private socket_url = new BehaviorSubject<string | null>(null);
  private api_url = new BehaviorSubject<string | null>(null);
  private user_token = new BehaviorSubject<string | null>(null);
  private account_token = new BehaviorSubject<string | null>(null);
  private account = new BehaviorSubject<string | null>(null);
  private server = new BehaviorSubject<string | null>(null);
  private theme = new BehaviorSubject<"light" | "dark" | null>(null);
  private _apiGlobalService: ApiGlobalService;

  private priceDataList = new BehaviorSubject<PriceData[]>([]);
  private currentSymbol = new BehaviorSubject<PriceData | null>(null);
  private symbolReady = new BehaviorSubject<boolean>(false);

  private subs: Subscription[] = [];

  constructor() {
    window.addEventListener("beforeunload", () => {
      this.destroy();
    });

    window.changeSymbol = this.changeSymbol.bind(this);
    window.changeTheme = this.changeTheme.bind(this);

    this._apiGlobalService = ApiGlobalService.initilize();

    this.set_symbol(this.searchParams.get("symbol") || null);
    this.set_socket_url(this.searchParams.get("socket_url") || null);
    this.set_api_url(this.searchParams.get("api_url") || null);
    this.set_user_token(this.searchParams.get("user_token") || null);
    this.set_account_token(this.searchParams.get("account_token") || null);
    this.set_account(this.searchParams.get("account") || null);
    this.set_server(this.searchParams.get("server") || null);
    this.set_theme(
      (this.searchParams.get("theme") as "light" | "dark") || null
    );

    this.subs.push(
      combineLatest([
        this.api_url,
        this.user_token,
        this.account_token,
        this.symbol,
      ])
        .pipe(
          startWith([null, null, null, null]), // Ensure pairwise has an initial value
          pairwise(),
          distinctUntilChanged()
        )
        .subscribe(
          async ([
            [prevApiUrl, prevUserToken, prevAccountToken, prevSymbol],
            [currApiUrl, currUserToken, currAccountToken, currSymbol],
          ]) => {
            if (currApiUrl && currUserToken && currAccountToken && currSymbol) {
              if (
                prevUserToken === currUserToken &&
                prevAccountToken === currAccountToken &&
                prevApiUrl === currApiUrl
              ) {
                // Only symbol changed. No need to fetch cashed data
                const currentSymbol = this.get_priceDataList().find(
                  (priceData) => priceData.symbol === currSymbol
                );
                if (currentSymbol) {
                  this.set_currentSymbol(currentSymbol);
                  this.set_symbolReady(true);
                }
                // }
              } else {
                setTimeout(async () => {
                  this.getSymbols(currSymbol);
                });
                setTimeout(async () => {
                  this.getSymbols("");
                });
              }
            }
          }
        )
    );
  }

  private async getSymbols(currSymbol: string) {
    const priceDataReponse = await this._apiGlobalService.getCashed(currSymbol);

    if (!priceDataReponse.success) {
      _globalService.postMessage(
        JSON.stringify({
          message: "Error fetching cached data - " + priceDataReponse.message,
        })
      );
      return;
    }

    const enriched = symbolsInitEnrichment(
      priceDataReponse.data as PriceData[]
    );
    this.append_priceDataList(enriched);
    const currentSymbol = enriched.find((item) => item.symbol === currSymbol);
    if (currentSymbol) {
      this.set_currentSymbol(currentSymbol);
      this.set_symbolReady(true);
    }
  }

  public changeSymbol(symbol: string) {
    this.set_symbolReady(false);
    this.set_symbol(symbol);
  }
  public changeTheme(theme: "light" | "dark") {
    this.set_theme(theme);
  }

  public initService() {
    const message = JSON.stringify({
      message: "FXGT ChartView Initialized @version: " + config.version,
    });
    this.postMessage(message);
  }

  public postMessage(message: string) {
    if (window.ReactNativeWebView === undefined) {
      console.log(message);
      return;
    }
    window.ReactNativeWebView.postMessage(message);
  }

  // Getters and Setters
  public set_symbol(symbol: string | null) {
    this.symbol.next(symbol);
  }
  public sub_symbol() {
    return this.symbol.asObservable();
  }
  public get_symbol() {
    return this.symbol.value;
  }

  public set_socket_url(socket_url: string | null) {
    this.socket_url.next(socket_url);
  }
  public sub_socket_url() {
    return this.socket_url.asObservable();
  }
  public get_socket_url() {
    return this.socket_url.value;
  }

  public set_api_url(api_url: string | null) {
    this.api_url.next(api_url);
  }
  public sub_api_url() {
    return this.api_url.asObservable();
  }
  public get_api_url() {
    return this.api_url.value;
  }

  public set_user_token(user_token: string | null) {
    this.user_token.next(user_token);
  }
  public sub_user_token() {
    return this.user_token.asObservable();
  }
  public get_user_token() {
    return this.user_token.value;
  }

  public set_account_token(account_token: string | null) {
    this.account_token.next(account_token);
  }
  public sub_account_token() {
    return this.account_token.asObservable();
  }
  public get_account_token() {
    return this.account_token.value;
  }

  public set_account(account: string | null) {
    this.account.next(account);
  }
  public sub_account() {
    return this.account.asObservable();
  }
  public get_account() {
    return this.account.value;
  }

  public set_server(server: string | null) {
    this.server.next(server);
  }
  public sub_server() {
    return this.server.asObservable();
  }
  public get_server() {
    return this.server.value;
  }

  public set_theme(theme: "light" | "dark" | null) {
    this.theme.next(theme);
  }
  public sub_theme() {
    return this.theme.asObservable();
  }
  public get_theme() {
    return this.theme.value;
  }

  public sub_priceDataList() {
    return this.priceDataList.asObservable();
  }
  public get_priceDataList() {
    return this.priceDataList.value;
  }
  public append_priceDataList(priceDataList: PriceData[]) {
    // Combine the existing list with the new list
    const combinedList = [...this.priceDataList.value, ...priceDataList];

    // Create a new list with unique items based on the symbol field
    const uniqueList = combinedList.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.symbol === item.symbol)
    );

    // Update the priceDataList with the unique list
    this.priceDataList.next(uniqueList);
  }

  public sub_currentSymbol() {
    return this.currentSymbol.asObservable();
  }
  public get_currentSymbol() {
    return this.currentSymbol.value;
  }
  public set_currentSymbol(currentSymbol: PriceData | null) {
    this.currentSymbol.next(currentSymbol);
  }
  public update_currentSymbol(incomingPriceData: PriceData) {
    const currentSymbol = this.get_currentSymbol();
    if (currentSymbol && currentSymbol.symbol === incomingPriceData.symbol) {
      const updated = { ...currentSymbol, ...incomingPriceData };
      this.set_currentSymbol(updated);
    }
  }

  public sub_symbolReady() {
    return this.symbolReady.asObservable();
  }
  public get_symbolReady() {
    return this.symbolReady.value;
  }
  public set_symbolReady(symbolReady: boolean) {
    this.symbolReady.next(symbolReady);
  }

  public getApiGlobalService() {
    return this._apiGlobalService;
  }

  public destroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}

export const _globalService = new GlobalService();
