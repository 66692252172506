import axios from "axios";
import {
  ApiResponse,
  ChartValuesParams,
  getCandleResolutionRouteParam,
  PriceData,
} from "../models/global.models";
import { _globalService } from "../services/_global.service";
import {
  calculateTradingHours,
  mapCacheDataWithAdditionallProperties,
  removeUnnecessaryItems,
  responseParseCachedData,
  setCorrectUsdRateSymbol,
} from "../helpers/api.helper";
import { Bar } from "../../public/charting_library/charting_library";
import { _timeService } from "../services/_time.service";

export class ApiGlobalService {
  private static instance: ApiGlobalService;

  private constructor() {
    axios.interceptors.request.use((request) => {
      const accountToken = _globalService.get_account_token();
      request.headers["gt-trade-account"] = accountToken;
      request.headers.Authorization = `Bearer ${_globalService.get_user_token()}`;

      return request;
    });
  }

  public static initilize = () => {
    if (!ApiGlobalService.instance) {
      ApiGlobalService.instance = new ApiGlobalService();
    }
    return ApiGlobalService.instance;
  };

  // Symbols
  public getCashed = async (symbol: string): Promise<ApiResponse> => {
    let url = _globalService.get_api_url() + "/market-watch/cached-stream-data";
    try {
      const queryParams: any = {};
      if (symbol) {
        queryParams.symbol = symbol;
      }
      const res = await axios.get(url, { params: queryParams });
      const success = res.status === 200 ? true : false;
      if (success) {
        // Not sure if this mapping should happend here
        const value: PriceData[] = res.data
          .map(responseParseCachedData)
          .filter((item: any) => item)
          .filter((item: any) => {
            return "symbolView" in item;
          })
          .map(
            mapCacheDataWithAdditionallProperties({
              current: false,
              starOn: false,
              up: undefined,
            })
          )
          .filter(removeUnnecessaryItems("PING"))
          .sort((item_a: PriceData, item_b: PriceData) => {
            return item_a.symbol > item_b.symbol;
          });
        // Fix for incorrect USD rate symbols coming from pump
        // TODO: remove once correct data comes from backend
        value.forEach((price: PriceData) => {
          setCorrectUsdRateSymbol(price, res.data);
        });
        // Calculate working hours
        value.forEach((price: PriceData) => {
          calculateTradingHours(price);
        });
        const response = {
          data: value,
          success: true,
          message: res.statusText,
        };
        return response;
      } else {
        return this.handleError(res.statusText);
      }
    } catch (error) {
      return this.handleError(error);
    }
  };

  public getChartRange = async (
    params: ChartValuesParams,
    resolutionIsMoreThanDay: boolean
  ): Promise<ApiResponse> => {
    const url = _globalService.get_api_url() + "/chart-range";
    try {
      const res = await axios.post(url, {
        period: getCandleResolutionRouteParam.get(params.period),
        symbol: params.symbol,
        count_back: params.count_back,
        date_from: params.date_from,
        date_to: params.date_to,
        first_data_request: params.first_data_request,
      });

      const success = res.status === 200 ? true : false;

      if (success) {
        const bars = Object.keys(res.data).map((key: string) => {
          const timestamp = parseInt(key);

          const parsedItem = res.data[timestamp].bid;
          const { first, last, min, max } = parsedItem;

          const object: Bar = {
            time: resolutionIsMoreThanDay
              ? _timeService.adjustToNextMidnightUTC(timestamp)
              : _timeService.convertServerTimeToGMT0(timestamp),
            open: first,
            high: max,
            low: min,
            close: last,
          };
          return object;
        });
        const sortedbars = bars.sort((a, b) => a.time - b.time);

        const response = {
          data: sortedbars,
          success: true,
          message: res.statusText,
        };
        return response;
      } else {
        return this.handleError(res.statusText);
      }
    } catch (error) {
      return this.handleError(error);
    }
  };

  private handleError = (error: any): ApiResponse => {
    return {
      data: null,
      success: false,
      message: error.message,
    };
  };
}
