import { PriceData, SymbolView } from "../models/global.models";

export const responseParseCachedData = (item: string) => {
  try {
    return item;
  } catch (c) {
    return JSON.parse(item);
  }
};
export const mapCacheDataWithAdditionallProperties =
  (props: {}) => (item: PriceData) => ({
    ...item,
    ...props,
  });

export const removeUnnecessaryItems = (symbol: string) => (item: PriceData) =>
  item.symbol !== symbol;

export const setCorrectUsdRateSymbol = (
  price: PriceData,
  prices: PriceData[]
) => {
  setCorrectUsdRateSymbolView(price.symbolView, prices);
};

export const setCorrectUsdRateSymbolView = (
  symbolView: SymbolView,
  prices: PriceData[]
) => {
  const newUsdRate = prices.find((p) =>
    p.symbol.startsWith(symbolView.USDRate)
  )?.symbol;
  if (newUsdRate) {
    symbolView.USDRate = newUsdRate;
  }
};
export const calculateTradingHours = (price: PriceData): void => {
  if (!price || !price?.trading_hours) return;
  const symbolData = price.trading_hours;
  const now = new Date();
  const currentMinutes = now.getHours() * 60 + now.getMinutes();
  let currentDayOfWeek = now.getDay();

  let isOpen = false;
  let changeOn: number | null = null;
  let isAlwaysOpen = true;

  const getChangeTimestamp = (dayOffset: number, minutes: number): number => {
    const targetDate = new Date(now);
    targetDate.setDate(now.getDate() + dayOffset);
    targetDate.setHours(Math.floor(minutes / 60) % 24);
    targetDate.setMinutes(minutes % 60);
    targetDate.setSeconds(0);
    targetDate.setMilliseconds(0);
    return Math.floor(targetDate.getTime() / 1000); // Return as Unix timestamp
  };

  for (let i = 0; i < 7; i++) {
    const dayIndex = (currentDayOfWeek + i) % 7;
    const daySessions = symbolData.find((item) => item.dayOfWeek === dayIndex);

    if (daySessions && daySessions.symbolSessions.length > 0) {
      for (const session of daySessions.symbolSessions) {
        const openTime = session.Open;
        const closeTime = session.Close;

        if (i === 0) {
          // Check if market is open today and if it closes
          if (openTime <= currentMinutes && currentMinutes < closeTime) {
            isOpen = true;
            if (closeTime !== 1440) {
              isAlwaysOpen = false;
              changeOn = getChangeTimestamp(0, closeTime);
              price.open_hours = {
                is_open: isOpen,
                change_on: changeOn,
                is_always_open: isAlwaysOpen,
              };
              return;
            }
          } else if (currentMinutes < openTime) {
            isOpen = false;
            isAlwaysOpen = false;
            changeOn = getChangeTimestamp(0, openTime);
            price.open_hours = {
              is_open: isOpen,
              change_on: changeOn,
              is_always_open: isAlwaysOpen,
            };
            return;
          }
        }

        if (i > 0) {
          if (isOpen) {
            if (
              closeTime !== 1440 &&
              (changeOn === null || changeOn > getChangeTimestamp(i, closeTime))
            ) {
              changeOn = getChangeTimestamp(i, closeTime);
              price.open_hours = {
                is_open: isOpen,
                change_on: changeOn,
                is_always_open: isAlwaysOpen,
              };
              return;
            }
          } else {
            changeOn = getChangeTimestamp(i, openTime);
            price.open_hours = {
              is_open: isOpen,
              change_on: changeOn,
              is_always_open: isAlwaysOpen,
            };
            return;
          }
        }

        if (session.Close !== 1440) {
          isAlwaysOpen = false;
        }
      }
    } else {
      const closeTime = 1440; // Midnight of the dayIndex day
      if (isOpen) {
        changeOn = getChangeTimestamp(i, closeTime);
        isAlwaysOpen = false;
        break; // Exit the loop as changeOn is set
      }
      isAlwaysOpen = false;
    }

    if (changeOn !== null && changeOn === getChangeTimestamp(i, 1440)) {
      changeOn = null;
    }
  }

  price.open_hours = {
    is_open: isOpen,
    change_on: changeOn,
    is_always_open: isAlwaysOpen,
  };
};
