import {
  Bar,
  DatafeedConfiguration,
  EntityId,
  HistoryCallback,
  IBasicDataFeed,
  IChartingLibraryWidget,
  LibrarySymbolInfo,
  PeriodParams,
  ResolutionString,
  ResolveCallback,
  SubscribeBarsCallback,
  SymbolResolveExtension,
  Timezone,
} from "../../public/charting_library";

import { debounce } from "lodash";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import _ from "lodash";
import { getCandleResolutionMinutes, PriceData } from "../models/global.models";
import { _globalService } from "./_global.service";
import { _timeService } from "./_time.service";

export class DataFeedHelper implements IBasicDataFeed {
  private stopRecievigUpdatesFlag: boolean = false;

  private lastBar = new BehaviorSubject<Bar | null>(null);
  private isSymbolSearch = false;
  private resolution = new BehaviorSubject<ResolutionString>(
    "1" as ResolutionString
  );
  private widget: IChartingLibraryWidget | null = null;
  private onTick: SubscribeBarsCallback = (bar: Bar): void => {};
  private askLineId: EntityId | null = null;
  private theme = new BehaviorSubject<string>("light");
  private subscriberUID = "";
  private debounceInterval: number = 200;
  private subs: Subscription[] = [];
  private lastTick: PriceData | null = null;
  private intervalRef: NodeJS.Timeout | null = null;
  private chartReady = false;

  constructor() {
    this.getFormatOptions = this.getFormatOptions.bind(this);
    this.subs[0] = combineLatest([
      _globalService.sub_currentSymbol(),
    ]).subscribe(([symbol]) => {
      if (!symbol) return;

      if (this.stopRecievigUpdatesFlag) {
        return;
      }
      if (this.lastTick !== null && this.lastTick?.symbol !== symbol.symbol) {
        if (this.widget) {
          this._resetLastBar();
          this.onResetCacheNeededCallback();
          _dataFeedHelperService.setChangingSymbol(true);
          this.widget.chart().setSymbol(symbol.symbol);
        }
      }
      this.lastTick = symbol;
      this.addTick(symbol);
    });

    this.initBarCreationCheckInterval();

    // cleanup
    window.addEventListener("beforeunload", () => {
      this.destroy();
    });
  }
  public destroy = (): void => {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    if (this.intervalRef) {
      clearInterval(this.intervalRef);
    }
    this._resetLastBar();
    this.chartReady = false;
  };

  private initBarCreationCheckInterval = (): void => {
    this.intervalRef = setInterval(() => {
      if (!this.chartReady) return;
      const lastBar = this._getLastBar();
      if (!lastBar) return;
      if (this.stopRecievigUpdatesFlag) return;
      if (_globalService.get_symbolReady() === false) return;
      const closeBarTime =
        lastBar.time +
        this.convertResolutionToMiliseconds(this.resolution.value);
      const timeNowInMoscow = _timeService.nowGMT0();

      // const timeUntilNewBar = closeBarTime - timeNowInMoscow; // Calculate time until new bar

      if (timeNowInMoscow > closeBarTime) {
        if (this.lastTick) {
          const tickTime = _timeService.convertServerTimeToGMT0(
            this.lastTick.dateTimeMSC
          );
          if (closeBarTime < tickTime) {
            const tickTimeAsBarTime = this.flattenTimeToBarTime(
              tickTime,
              this.resolution.value
            );
            if (tickTimeAsBarTime > lastBar.time) {
              // console.log('initiating new bar');
              this.createNewBarOnInterval(tickTimeAsBarTime, this.lastTick);
            }
          }
        }
      } else {
        // console.log(
        //   'Time until new bar:',
        //   Math.round(timeUntilNewBar / 1000),
        //   'seconds'
        // );
      }
    }, 600);
  };

  private flattenTimeToBarTime = (
    time: number,
    resolution: ResolutionString
  ) => {
    const resolutionInMilisec = getCandleResolutionMinutes.get(resolution)!;
    const timeInBars = Math.floor(time / resolutionInMilisec);
    return timeInBars * resolutionInMilisec;
  };

  protected supported_resolutions = [
    "1",
    "5",
    "15",
    "30",
    "60",
    "240",
    "1D",
    "1W",
    "1M",
  ] as ResolutionString[];

  private configurationData: DatafeedConfiguration = {
    supported_resolutions: this.supported_resolutions,
    exchanges: [],
    symbols_types: [],
  };

  onReady(callback: any): void {
    setTimeout(() => {
      callback(this.configurationData);
    });
  }

  onResetCacheNeededCallback(): void {
    if (this.widget && this.widget.chart) {
      const chart = this.widget.chart();
      if (chart) {
        const lastBar = this._getLastBar();
        if (lastBar) {
          this.onTick(_.cloneDeep(lastBar));
        }
        chart.resetData();
      }
    } else {
      console.log("NO WIDGET REFERENCE");
    }
  }

  private convertResolutionToMiliseconds(resolution: ResolutionString): number {
    return getCandleResolutionMinutes.get(resolution)!;
  }

  private createNewBarOnInterval(time: number, symbol: PriceData) {
    const lastBar = this._getLastBar();
    let open = 0;
    if (lastBar) {
      const resolution = this.resolution.value;
      // Get resolution in minutes
      const resolutionUnitInMinutes =
        this.resolutionToUnitInMinutes(resolution);
      // Get time gap from prev bar
      const gapInMinutes = (time - lastBar.time) / (1000 * 60);

      // If the time is more than the resolution, dont use prev bar close as open
      if (gapInMinutes > resolutionUnitInMinutes) {
        // console.log(
        //   'Gap is more than resolution',
        //   gapInMinutes,
        //   resolutionUnitInMinutes
        // );
        open = symbol.bid;
      } else {
        // console.log(
        //   'Gap is less than resolution',
        //   gapInMinutes,
        //   resolutionUnitInMinutes
        // );
        open = lastBar.close;
      }
    } else {
      // console.log('No last bar found');
      open = symbol.bid;
    }

    const updatedBar = {
      time: time,
      open: open,
      high: symbol.bid,
      low: symbol.bid,
      close: symbol.bid,
      volume: symbol.volume || 0,
    };
    this._updateLastBar(updatedBar);
    this.onTick(_.cloneDeep(updatedBar));
  }

  private updateBar(bar: Bar, symbol: PriceData): Bar {
    const updatedBar: Bar = { ...bar };
    updatedBar.close = symbol.bid;
    updatedBar.high = Math.max(updatedBar.high, symbol.bid);
    updatedBar.low = Math.min(updatedBar.low, symbol.bid);
    if (typeof updatedBar.volume == "undefined") {
      updatedBar.volume = symbol.volume || 0;
    } else {
      updatedBar.volume += symbol.volume || 0;
    }
    return updatedBar;
  }

  private addTick(tick: PriceData): void {
    if (this.stopRecievigUpdatesFlag) {
      return;
    }
    // Calculate the start time of the current bar
    let lastBar = this._getLastBar();
    const currentBarTime = lastBar ? lastBar.time : 0;

    const tickTime = _timeService.convertServerTimeToGMT0(tick.dateTimeMSC);

    if (currentBarTime > tickTime) {
      // Incoming tick is older than the last bar - return
      console.warn(
        "Incoming tick is older than the last bar check if tick is Old or timestamps at the bars are wrong"
      );
      return;
    }
    if (lastBar) {
      // If there are bars and the tick is in the current bar's time range, update the last bar
      lastBar = this.updateBar(lastBar, tick);
      this._updateLastBar(lastBar);
    }
    if (!this.stopRecievigUpdatesFlag) {
      // console.log('addTick onTick:', lastBar);
      if (lastBar) this.onTick(_.cloneDeep(lastBar));
    }
    try {
      if (this.widget) {
        const chart = this.widget.chart();
        const askPrice = tick.ask;

        // Remove previous ask line if it exists
        if (this.askLineId) {
          chart.removeEntity(this.askLineId); // Remove the previous line shape
        }

        // Add a new shape to mark the stop loss price
        this.askLineId = chart.createShape(
          { price: askPrice, time: tick.dateTimeMSC },
          {
            shape: "horizontal_line",
            overrides: {
              linestyle: 1,
              linewidth: 1,
              linecolor: this.theme.value === "dark" ? "#03E4BA" : "#003be5",
              textcolor: "#FFFFFF",
            },
          }
        );
      }
    } catch (error) {}
  }

  public setTheme(theme: string): void {
    this.theme.next(theme);
  }

  resolveSymbol(
    symbolName: string,
    onSymbolResolvedCallback: ResolveCallback,
    onError: TradingView.ErrorCallback,
    extension?: SymbolResolveExtension | undefined
  ): void {
    const digits = _globalService.get_currentSymbol()?.symbolView?.Digits || 0;
    const description =
      _globalService.get_currentSymbol()?.symbolView?.Description;
    setTimeout((): void => {
      onSymbolResolvedCallback({
        supported_resolutions: this.supported_resolutions,
        name: symbolName,
        description: description || symbolName,
        exchange: "",
        type: "fiat",
        format: "price",
        has_intraday: true,
        intraday_multipliers: ["1", "5", "15", "30", "60", "240"],
        has_daily: true,
        minmov: 1,
        timezone: _timeService.getCurrentTimeZone() as Timezone,
        session: "24x7",
        listed_exchange: "",
        has_weekly_and_monthly: true,
        has_ticks: true,
        pricescale: 10 ** digits,
        data_status: "streaming",
        has_seconds: true,
      });
    }, 200);
    if (this.isSymbolSearch) {
    }
  }

  private getFormatOptions(): {
    date: CustomDateTimeFormatOptions;
    time: CustomDateTimeFormatOptions;
  } {
    const minuteBasedFormatOptions: {
      date: CustomDateTimeFormatOptions;
      time: CustomDateTimeFormatOptions;
    } = {
      date: { month: "short", day: "2-digit", year: "numeric" },
      time: {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      },
    };

    const dayBasedFormatOptions: {
      date: CustomDateTimeFormatOptions;
      time: CustomDateTimeFormatOptions;
    } = {
      date: { month: "short", day: "2-digit", year: "numeric" },
      time: {},
    };

    const weekBasedFormatOptions: {
      date: CustomDateTimeFormatOptions;
      time: CustomDateTimeFormatOptions;
    } = {
      date: { month: "short", year: "numeric" },
      time: {},
    };

    const formatOptions: Record<
      string,
      { date: CustomDateTimeFormatOptions; time: CustomDateTimeFormatOptions }
    > = {
      "1": minuteBasedFormatOptions,
      "5": minuteBasedFormatOptions,
      "15": minuteBasedFormatOptions,
      "30": minuteBasedFormatOptions,
      "60": minuteBasedFormatOptions,
      "240": minuteBasedFormatOptions,

      "1D": dayBasedFormatOptions,
      "4D": dayBasedFormatOptions,

      "1W": weekBasedFormatOptions,
      "1M": weekBasedFormatOptions,
    };

    return formatOptions[this.resolution.value] || { time: {}, date: {} };
  }

  formatDate(date: Date): string {
    const options = this.getFormatOptions()?.date || {};
    options.timeZone = "UTC";
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  formatTime(date: Date): string {
    const options = this.getFormatOptions()?.time || {};
    options.timeZone = "UTC";
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onResult: HistoryCallback,
    onError: TradingView.ErrorCallback
  ): void {
    let resolutionIsMoreThanDay = true;
    let visibleRangeFrom =
      periodParams.from - this.calculatePadding(resolution);

    let visibleRangeTo = periodParams.to;

    // If it is the first data request,
    // expand chart range as if last bar was
    // the end of visible range.
    if (periodParams.firstDataRequest) {
      const currentSymbol = _globalService.get_currentSymbol();
      const latest = currentSymbol?.["chart-data"]?.latest;
      if (latest) {
        visibleRangeFrom = latest - (visibleRangeTo - visibleRangeFrom);
      }
    }

    if (resolution !== "1D" && resolution !== "1W" && resolution !== "1M") {
      visibleRangeFrom =
        _timeService.getTimeInZoneSecWithOffsetFromUtc(visibleRangeFrom);
      visibleRangeTo =
        _timeService.getTimeInZoneSecWithOffsetFromUtc(visibleRangeTo);
      resolutionIsMoreThanDay = false;
    }

    // visibleRangeTo -= 1200; // Deduct 20 minutes (1200 seconds) from visibleRangeTo (simulation)

    const debounced = () => {
      _globalService
        .getApiGlobalService()
        .getChartRange(
          {
            period: resolution,
            symbol: symbolInfo.name,
            count_back: periodParams.countBack,
            date_from: visibleRangeFrom,
            date_to: visibleRangeTo,
            first_data_request: periodParams.firstDataRequest,
          },
          resolutionIsMoreThanDay
        )
        .then((res) => {
          if (res.success) {
            const data = res.data as Bar[];
            if (periodParams.firstDataRequest) {
              // asign the last bar from response to the last bar subject
              this._asignLastBar(data[data.length - 1]);
            }
            if (data.length > 0) {
              onResult(data, { noData: !data.length });
            } else {
              if (periodParams.firstDataRequest) {
                // Create new bar from scratch using the current tick - initiate chart without data
                this.createInitialBar();
              }
              onResult([], { noData: true });
            }
          } else {
            onResult([], { noData: true });
            onError(res.message);
          }
        });
    };
    debounce(debounced, this.debounceInterval)();
  }

  private createInitialBar = () => {
    // Create new bar from scratch using the current tick - initiate chart without data
    const tick = _globalService.get_currentSymbol();
    if (tick) {
      const timeNowInMoscow = _timeService.nowGMT0();
      const tickTimeMinute = Math.floor(timeNowInMoscow / 60000) * 60000;
      setTimeout(() => {
        this.createNewBarOnInterval(tickTimeMinute, tick);
      }, 1000);
    }
  };

  private calculatePadding(resolution: ResolutionString): number {
    // Padding based on resolution
    switch (resolution) {
      case "1":
        return 3000 * 60;
      case "5":
        return 600 * 5 * 60;
      case "15":
        return 200 * 15 * 60;
      case "30":
        return 200 * 30 * 60;
      case "60":
        return 200 * 60 * 60;
      case "240":
        return 200 * 4 * 60 * 60;
      case "1D":
        return 200 * 24 * 60 * 60;
      case "4D":
        return 200 * 4 * 24 * 60 * 60;
      case "1W":
        return 200 * 7 * 24 * 60 * 60;
      case "1M":
        return 200 * 30 * 24 * 60 * 60;
      default:
        return 200 * 60;
    }
  }

  searchSymbols(
    userInput: string,
    exchange: any,
    symbolType: any,
    onResultReadyCallback: any
  ): void {}

  subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: TradingView.SubscribeBarsCallback,
    subscriberUID: string,
    onResetCacheNeededCallback: () => void
  ): void {
    this.stopRecievigUpdatesFlag = false;
    this.onTick = onTick;
    this.onResetCacheNeededCallback = onResetCacheNeededCallback;
    this.resolution.next(resolution);
    this.subscriberUID = subscriberUID;
  }

  unsubscribeBars(subscriberUID: string): void {
    if (this.subscriberUID === subscriberUID) {
      this.stopRecievigUpdatesFlag = true;
    }
  }

  setWidget(w: IChartingLibraryWidget): void {
    this.widget = w;
    this.widget.onChartReady(() => {
      this.widget
        ?.chart()
        .onIntervalChanged()
        .subscribe(null, (interval) => {
          this._resetLastBar();
          this.onResetCacheNeededCallback();
        });
      this.chartReady = true;

      this.widget
        ?.chart()
        .onSymbolChanged()
        .subscribe(null, () => {
          _dataFeedHelperService.setChangingSymbol(false);
          this.widget?.activeChart().executeActionById("chartReset");
        });
    });
  }

  private resolutionToUnitInMinutes(resolution: ResolutionString): number {
    switch (resolution) {
      case "1":
        return 1;
      case "5":
        return 5;
      case "15":
        return 15;
      case "30":
        return 30;
      case "60":
        return 60;
      case "240":
        return 240;
      case "1D":
        return 1440;
      case "1W":
        return 10080;
      case "1M":
        return 43200;
      default:
        return 1;
    }
  }

  private _asignLastBar = (bar: Bar | null): void => {
    if (!bar) return;
    const barCloned = _.cloneDeep(bar);
    this.lastBar.next(barCloned);
  };
  private _getLastBar = (): Bar | null => {
    return _.cloneDeep(this.lastBar.value);
  };
  private _updateLastBar = (bar: Bar): void => {
    this.lastBar.next(_.cloneDeep(bar));
  };
  private _resetLastBar = (): void => {
    this.lastBar.next(null);
  };
}

interface CustomDateTimeFormatOptions {
  month?: "short" | "long" | "numeric";
  day?: "2-digit" | "numeric";
  year?: "2-digit" | "numeric";
  hour?: "2-digit" | "numeric";
  minute?: "2-digit" | "numeric";
  second?: "2-digit" | "numeric";
  hour12?: boolean;
  timeZone?: string;
}

class DataFeedHelperService {
  private changingSymbol = new BehaviorSubject<boolean>(false);

  public sub_ChangingSymbol = (): BehaviorSubject<boolean> => {
    return this.changingSymbol;
  };
  public setChangingSymbol = (changing: boolean): void => {
    this.changingSymbol.next(changing);
  };
  public getChangingSymbol = (): boolean => {
    return this.changingSymbol.value;
  };
}
export const _dataFeedHelperService = new DataFeedHelperService();
