import { useState } from "react";
import "./intervalSwitcher.scss";
import { ReactComponent as CandleStickChartIcon } from "../../assets/icons/candlestick-chart.svg";
import { ReactComponent as AreaChartIcon } from "../../assets/icons/area-chart.svg";

interface Props {
  onIntervalChange: (interval: string) => void;
  toggleChartType: () => void;
  activeChartType: number;
}

const intervals = [
  { id: "1", label: "1m" },
  { id: "5", label: "5m" },
  { id: "15", label: "15m" },
  { id: "30", label: "30m" },
  { id: "60", label: "60m" },
  { id: "240", label: "4h" },
  { id: "1D", label: "1D" },
  { id: "4D", label: "4D" },
  { id: "1W", label: "1W" },
  { id: "1M", label: "1M" },
];

const IntervalSwitcher = ({
  onIntervalChange,
  toggleChartType,
  activeChartType,
}: Props) => {
  const [activeInterval, setActiveInterval] = useState("1");

  return (
    <div id="custom-toolbar">
      <div className="custom-toolbar-interval-switch">
        {intervals.map((interval) => (
          <button
            key={interval.id}
            id={interval.id}
            className={`interval-btn ${
              activeInterval === interval.id ? "active" : ""
            }`}
            onClick={() => {
              setActiveInterval(interval.id);
              onIntervalChange(interval.id);
            }}
          >
            {interval.label}
          </button>
        ))}
        <div className="custom-toolbar-chart-type-switch">
          <div
            onClick={toggleChartType}
            id="icon-container"
            className="no-select"
          >
            {activeChartType === 1 ? (
              <CandleStickChartIcon />
            ) : (
              <AreaChartIcon />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntervalSwitcher;
