import { useCurrentSymbol, usePriceDataList } from "../hooks/global.hooks";
import { _globalService } from "../services/_global.service";
import "./debug.scss";

const Debug = () => {
  const { priceDataList } = usePriceDataList();
  const { symbol } = useCurrentSymbol();

  const handleClick = (symbol: string) => {
    _globalService.changeSymbol(symbol);
  };
  return (
    <div className="debug">
      {priceDataList.map((priceData) => (
        <div
          onClick={() => handleClick(priceData.symbol)}
          key={priceData.symbol}
          className="price-data"
        >
          <span className={`${symbol === priceData.symbol ? "selected" : ""}`}>
            {priceData.symbol}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Debug;
