import { useEffect, useState } from "react";
import { _globalService } from "../services/_global.service";
import { PriceData } from "../models/global.models";
import { _chartReadyService } from "../services/_chartReady.service";

export const useCurrentSymbol = () => {
  const [currentSymbol, setCurrentSymbol] = useState<PriceData | null>(null);
  const [symbol, setSymbol] = useState<string | null>(null);
  const [symbolReady, setSymbolReady] = useState<boolean>(false);

  useEffect(() => {
    const subs = [
      _globalService.sub_currentSymbol().subscribe((currentSymbol) => {
        setCurrentSymbol(currentSymbol);
      }),

      _globalService.sub_symbol().subscribe((symbol) => {
        setSymbol(symbol);
      }),

      _globalService.sub_symbolReady().subscribe((symbolReady) => {
        setSymbolReady(symbolReady);
      }),
    ];

    return () => {
      subs.forEach((sub) => sub.unsubscribe());
    };
  }, []);

  return { currentSymbol, symbol, symbolReady };
};

export const useTheme = () => {
  const [theme, setTheme] = useState<"light" | "dark" | null>(null);

  useEffect(() => {
    const sub = _globalService.sub_theme().subscribe((theme) => {
      setTheme(theme);
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return { theme };
};

export const useChartReady = () => {
  const [chartIsReady, setChartIsReady] = useState(
    _chartReadyService.getChartIsReady()
  );

  useEffect(() => {
    const sub = _chartReadyService.subToChartReady().subscribe((value) => {
      setChartIsReady(value);
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);
  return { chartIsReady };
};

export const useWsData = () => {
  const [account_token, setAccount_token] = useState<string | null>(null);
  const [socket_url, setSocketUrl] = useState<string | null>(null);
  const [server, setServer] = useState<string | null>(null);
  const [account, setAccount] = useState<string | null>(null);

  useEffect(() => {
    const subs = [
      _globalService.sub_account_token().subscribe((token) => {
        setAccount_token(token);
      }),

      _globalService.sub_socket_url().subscribe((url) => {
        setSocketUrl(url);
      }),

      _globalService.sub_server().subscribe((server) => {
        setServer(server);
      }),

      _globalService.sub_account().subscribe((account) => {
        setAccount(account);
      }),
    ];

    return () => {
      subs.forEach((sub) => sub.unsubscribe());
    };
  }, []);

  return { account_token, socket_url, server, account };
};

export const useDebugMode = () => {
  const [debugMode, setDebugMode] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("debugMode");
    return storedValue === "true";
  });

  return { debugMode };
};

export const usePriceDataList = () => {
  const [priceDataList, setPriceDataList] = useState<PriceData[]>([]);

  useEffect(() => {
    const sub = _globalService
      .sub_priceDataList()
      .subscribe((priceDataList) => {
        setPriceDataList(priceDataList);
      });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return { priceDataList };
};
