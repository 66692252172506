import { useEffect } from "react";
import "./App.scss";
import { _globalService } from "./services/_global.service";
import Chart from "./chart/chart";
import { useDebugMode, useTheme } from "./hooks/global.hooks";
import Debug from "./debug/debug";
import { usePriceWS } from "./hooks/ws.hook";

function App() {
  const { theme } = useTheme();
  const { debugMode } = useDebugMode();
  useEffect(() => {
    _globalService.initService();
  }, []);

  usePriceWS();

  return (
    <div
      className={`tr-${theme}-theme main-container ${debugMode ? "debug" : ""}`}
    >
      <Chart />
      {debugMode && <Debug />}
    </div>
  );
}

export default App;
