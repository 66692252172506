import { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  ChartingLibraryWidgetOptions,
  CustomFormatter,
  IChartingLibraryWidget,
  ResolutionString,
  ThemeName,
  Timezone,
} from "../../public/charting_library/charting_library";
import {
  useChartReady,
  useCurrentSymbol,
  useTheme,
} from "../hooks/global.hooks";
import { DataFeedHelper } from "../services/_dataFeed.service";
import { _timeService } from "../services/_time.service";
import { getOverrides } from "../helpers/tradingViewHelper";
import "./chart.scss";
import { _chartReadyService } from "../services/_chartReady.service";
import IntervalSwitcher from "./intervalSwitcher/intervalSwitcher";

const Chart = () => {
  const tradingViewContainerRef = useRef<HTMLDivElement>(null);
  const [widgetRef, setWidgetRef] = useState<
    IChartingLibraryWidget | undefined
  >(undefined);
  const { currentSymbol, symbolReady, symbol } = useCurrentSymbol();
  const widgetInitialized = useRef(false);
  const dataFeedRef = useRef<DataFeedHelper | null>(null);
  const { theme } = useTheme();
  const themeRef = useRef(theme);

  const { chartIsReady } = useChartReady();
  const [activeChartType, setActiveChartType] = useState<number | null>(null);

  const initializeWidget = useCallback(() => {
    if (symbol === null) return;
    if (currentSymbol === null) return;
    if (symbolReady === false) return;

    const startTime = performance.now(); // Capture the start time
    if (tradingViewContainerRef.current === null) {
      return;
    }

    _chartReadyService.setChartisReady(false);
    dataFeedRef.current = new DataFeedHelper();

    if (!dataFeedRef.current) return;
    const options: ChartingLibraryWidgetOptions = {
      container: tradingViewContainerRef.current?.id as string,
      autosize: true,
      load_last_chart: true,
      symbol: symbol,
      interval: "1" as ResolutionString,
      timezone: _timeService.getCurrentTimeZone() as Timezone,
      theme: theme as ThemeName,
      locale: "en",
      custom_css_url: "/TrendingViewChart.css",
      datafeed: dataFeedRef.current,
      loading_screen:
        theme === "dark"
          ? { backgroundColor: "#0a0a0a", foregroundColor: "#03E4BA" }
          : { backgroundColor: "#fff", foregroundColor: "#002ce0" },
      disabled_features: [
        "header_symbol_search",
        "symbol_search_hot_key",
        "timeframes_toolbar",
        "header_compare",
        "header_widget",
        "left_toolbar",
        "legend_widget",
        "timezone_menu",
        "save_chart_properties_to_local_storage",
        "header_settings",
        "property_pages",
        "create_volume_indicator_by_default",
      ],
      library_path: "/charting_library/",
      custom_font_family: "'Montserrat', monospace",
      overrides: getOverrides(theme as ThemeName),
      custom_formatters: {
        timeFormatter: {
          format: dataFeedRef.current.formatTime.bind(dataFeedRef.current),
          formatLocal: dataFeedRef.current.formatTime.bind(dataFeedRef.current),
          parse: (value: string): string => "",
        } as CustomFormatter,
        dateFormatter: {
          format: dataFeedRef.current.formatDate.bind(dataFeedRef.current),
          formatLocal: dataFeedRef.current.formatDate.bind(dataFeedRef.current),
          parse: (value: string): string => "",
        } as CustomFormatter,
      },
    };
    const newWidget = new (window as any).TradingView.widget(
      options
    ) as IChartingLibraryWidget;
    setWidgetRef(newWidget);

    // Add reference to widget in dataFeed
    dataFeedRef.current.setWidget(newWidget);
    dataFeedRef.current?.setTheme(theme as ThemeName);

    newWidget.onChartReady(() => {
      const currentChartType = newWidget.chart().chartType();
      setActiveChartType(currentChartType);

      newWidget
        .chart()
        .onChartTypeChanged()
        .subscribe(null, (chartType) => {
          setActiveChartType(chartType);
        });

      _chartReadyService.setChartisReady(true);

      const endTime = performance.now(); // Capture the end time
      console.log(`Time taken: ${endTime - startTime} milliseconds`); // Log the time difference
    });
  }, [currentSymbol, symbol, symbolReady, theme]);

  useEffect(() => {
    if (currentSymbol && symbolReady && symbol) {
      if (!widgetRef && !widgetInitialized.current) {
        initializeWidget();
        widgetInitialized.current = true;
        return;
      }
    }
  }, [currentSymbol, symbolReady, symbol, widgetRef, initializeWidget]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    const applyTheme = (theme_: ThemeName): void => {
      widgetRef?.changeTheme(theme_).then(() => {
        widgetRef?.applyOverrides(getOverrides(theme_));
        dataFeedRef.current?.setTheme(theme_);
      });
    };

    // Listen for changes in the 'theme' prop
    if (widgetRef) {
      if (chartIsReady && theme !== themeRef.current) {
        themeRef.current = theme;
        dataFeedRef.current?.setTheme(theme as ThemeName);
        applyTheme(theme as ThemeName);
      }
      return;
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [theme, widgetRef, chartIsReady]);

  const handleIntervalChange = useCallback(
    (interval: string) => {
      if (widgetRef) {
        widgetRef.chart().setResolution(interval as ResolutionString);
      }
    },
    [widgetRef]
  );

  const handleToggleChartType = useCallback(() => {
    if (widgetRef) {
      const currentChartType = widgetRef.chart().chartType();
      const newChartType = currentChartType === 1 ? 3 : 1;
      widgetRef.chart().setChartType(newChartType);
    }
  }, [widgetRef]);

  return (
    <>
      <div
        id={"tradingview-widget-container"}
        ref={tradingViewContainerRef}
        className={`${!chartIsReady ? "notLoaded" : ""}`}
      ></div>

      {!chartIsReady && (
        <div className="spinner">
          <span className="spinner-inner-1"></span>
          <span className="spinner-inner-2"></span>
          <span className="spinner-inner-3"></span>
        </div>
      )}
      {activeChartType ? (
        <IntervalSwitcher
          activeChartType={activeChartType}
          onIntervalChange={handleIntervalChange}
          toggleChartType={handleToggleChartType}
        />
      ) : (
        <div style={{ height: 41, backgroundColor: "transparent" }}></div>
      )}
    </>
  );
};

export default memo(Chart);
