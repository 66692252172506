import { PriceData } from "../models/global.models";

export const SERVER_GMT_OFFSET = 0;

export const symbolsInitEnrichment = (symbols: PriceData[]) => {
  symbols.forEach((item) => {
    item.time = getServerTimeStr(item.dateTimeMSC, "time");
  });
  return symbols;
};

export function toFixedWithTrailingZeroes(
  number: number,
  precision: number,
  useLocaleString: boolean = true
): string {
  // Convert number to fixed, which returns a string.
  let fixedString = number.toFixed(precision);

  // Trim any rounding errors introduced by toFixed.
  const dotIndex = fixedString.indexOf(".");
  if (dotIndex !== -1) {
    // If there's a decimal point
    const actualPrecision = fixedString.length - dotIndex - 1;
    if (actualPrecision > precision) {
      fixedString = fixedString.substring(0, dotIndex + precision + 1);
    } else {
      // Pad with trailing zeroes if necessary.
      while (actualPrecision < precision) {
        fixedString += "0";
      }
    }
  } else {
    // If there's no decimal point, add one with the required precision.
    fixedString += "." + "0".repeat(precision);
  }

  // Split the string into the integer and decimal parts.
  const [integerPart, decimalPart] = fixedString.split(".");

  // Format the integer part with commas as thousands separators.
  const formattedIntegerPart = useLocaleString
    ? parseInt(integerPart, 10).toLocaleString()
    : integerPart;

  // Return the formatted string.
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
}

export function calculateSpreadInPoints(symbol: PriceData): number {
  if (!symbol) return 0;
  if (symbol.symbolView === undefined) return 0;
  const spread = symbol.ask - symbol.bid;
  const spreadInPoints = spread * symbol.symbolView.Multiply;
  return spreadInPoints;
}
export const getServerTimeStr = (
  serverTimeMs: number,
  type: "date" | "time" = "date"
): string => {
  const datetime = new Date(
    serverTimeMs +
      new Date().getTimezoneOffset() * 60 * 1000 + // user TZ offset
      SERVER_GMT_OFFSET * 60 * 60 * 1000
  );

  return type === "date"
    ? datetime.toLocaleString("en-GB", {
        hour12: false,
      })
    : datetime.toLocaleTimeString("en-GB", {
        hour12: false,
      });
};
