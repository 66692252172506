import moment from "moment-timezone";

class Time {
  private timezone = "Europe/Moscow";
  public init = () => {
    // Fake constructor
    console.log("Time service initialized");
  };

  public getTime = () => {
    return moment().tz(this.timezone).valueOf();
  };

  public getTimeInLocaleFormat = () => {
    const userLocale = navigator.language;
    return moment().tz(this.timezone).locale(userLocale).format("L LTS");
  };

  public formatLeftOver = (
    serverTime: number
  ): { leftOverInMilisec: number; formatedDuration: string } => {
    const now = moment().unix() * 1000;
    serverTime = serverTime * 1000;
    const differenceInMilliseconds = serverTime - now;

    // Convert the difference from milliseconds to seconds
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

    // Calculate the days, hours, minutes, and seconds
    const days = Math.floor(differenceInSeconds / 86400);
    const hours = Math.floor((differenceInSeconds % 86400) / 3600);
    const minutes = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    // Initialize formattedDifference as an empty string
    let formattedDifference = "";

    // If days are greater than or equal to 1, include them in the formatted string
    if (days >= 1) {
      formattedDifference += `${days.toString().padStart(2, "0")}d `;
    }

    // If hours are greater than or equal to 1, include them in the formatted string
    if (hours >= 1) {
      formattedDifference += `${hours.toString().padStart(2, "0")}h `;
    }

    // If minutes are greater than or equal to 1, include them in the formatted string
    if (minutes >= 1) {
      formattedDifference += `${minutes.toString().padStart(2, "0")}m `;
    }

    // Always include seconds in the formatted string
    formattedDifference += `${seconds.toString().padStart(2, "0")}s`;

    return {
      leftOverInMilisec: differenceInMilliseconds,
      formatedDuration: formattedDifference,
    };
  };

  public convertTimeInMilisecondsToLocaleFormat = (time: number) => {
    const userLocale = navigator.language;
    const formattedTime = moment(time)
      .tz(this.timezone)
      .locale(userLocale)
      .format("HH:mm");
    return formattedTime;
  };

  public getTimeInZoneSecWithOffset = () => {
    const now = moment();
    const zoneOffset = now.tz(this.timezone).utcOffset(); // Get Moscow's current offset in minutes
    const utcNowInMilliseconds = now.valueOf(); // UTC now in milliseconds
    const zoneTimeInMilisec = utcNowInMilliseconds + zoneOffset * 60000; // Convert offset to milliseconds and add to UTC time

    return Math.floor(zoneTimeInMilisec / 1000);
  };

  public getTimeInZoneSecWithOffsetFromUtc = (
    utcTimeInMilliseconds: number
  ) => {
    const time = moment.utc(utcTimeInMilliseconds);
    const zoneOffset = time.tz(this.timezone).utcOffset();
    const zoneTimeInMilisec = utcTimeInMilliseconds + zoneOffset * 60;
    return zoneTimeInMilisec;
  };

  public convertServerTimeToGMT0 = (time: number) => {
    const now = moment(time);
    const zoneOffset = now.tz(this.timezone).utcOffset(); // Get Moscow's current offset in minutes
    const utcNowInMilliseconds = now.valueOf(); // UTC now in milliseconds
    const zoneTimeInMilisec = utcNowInMilliseconds - zoneOffset * 60000; // Convert offset to milliseconds and add to UTC time
    return zoneTimeInMilisec;
  };

  public adjustToNextMidnightUTC = (time: number) => {
    const date = new Date(time);
    date.setUTCHours(0, 0, 0, 0);
    date.setUTCDate(date.getUTCDate() + 1);
    return date.getTime();
  };

  public nowGMT0 = () => {
    const utcNow = moment.utc(); // Get current time in UTC
    return utcNow.valueOf(); // Return UTC now in milliseconds
  };

  public getCurrentTimeZone = () => {
    return this.timezone;
  };
}
export const _timeService = new Time();
