import { ColorTypes } from "../../public/charting_library/charting_library";

export const getOverrides = (theme_: string) => {
  return {
    "paneProperties.backgroundType": "solid" as ColorTypes,
    "paneProperties.background": theme_ === "dark" ? "#000" : "#F7F7F9",
    "scalesProperties.textColor": theme_ === "dark" ? "#999" : "#1c1c1c",
    "paneProperties.legendProperties.showBackground": false,
    "mainSeriesProperties.priceLineColor": theme_ === "dark" ? "red" : "red",
    "mainSeriesProperties.lineStyle.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.candleStyle.upColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.candleStyle.borderUpColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.candleStyle.wickUpColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.hollowCandleStyle.upColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.hollowCandleStyle.wickUpColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.haStyle.upColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.haStyle.borderUpColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.haStyle.wickUpColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.barStyle.upColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.hlcAreaStyle.highLineColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.renkoStyle.upColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.renkoStyle.borderUpColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.pbStyle.upColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.pbStyle.borderUpColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.kagiStyle.upColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.pnfStyle.upColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.baselineStyle.topLineColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetooldisjointangle.linecolor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetooldisjointangle.textcolor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolfibchannel.level16.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolfibchannel.level24.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolfibchannel.level5.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolfibcircles.level3.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolfibretracement.level16.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolfibretracement.level24.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolfibretracement.level5.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolfibspeedresistancearcs.level3.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolfibspeedresistancefan.hlevel5.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolfibspeedresistancefan.vlevel5.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolfibwedge.level4.color": theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolganncomplex.arcs.7.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolganncomplex.arcs.8.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolganncomplex.fanlines.6.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolganncomplex.fanlines.7.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolganncomplex.levels.4.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolgannfan.level2.color": theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolgannfan.level4.color": theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolgannfixed.arcs.7.color": theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolgannfixed.arcs.8.color": theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolgannfixed.fanlines.6.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolgannfixed.fanlines.7.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolgannfixed.levels.4.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolgannsquare.hlevel5.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolgannsquare.vlevel5.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolghostfeed.candleStyle.borderUpColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolheadandshoulders.backgroundColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolheadandshoulders.color": theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolinsidepitchfork.level2.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolinsidepitchfork.level3.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolpitchfan.level3.color": theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolpitchfork.level2.color": theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolpitchfork.level3.color": theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolschiffpitchfork2.level2.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolschiffpitchfork2.level3.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolschiffpitchfork.level2.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolschiffpitchfork.level3.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetooltrendbasedfibextension.level16.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetooltrendbasedfibextension.level24.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetooltrendbasedfibextension.level5.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetooltrendbasedfibtime.level5.color":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetooltriangle.color": theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolabcd.color": theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolarrowmarkup.arrowColor": theme_ === "dark" ? "#03E4BA" : "#003be5",
    "linetoolarrowmarkup.color": theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.hollowCandleStyle.borderUpColor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "paneProperties.vertGridProperties.color":
      theme_ === "dark" ? "#000" : "#F7F7F9",
    "paneProperties.horzGridProperties.color":
      theme_ === "dark" ? "#000" : "#F7F7F9",
    "scalesProperties.lineColor": theme_ === "dark" ? "#000" : "#F7F7F9",
    "scalesProperties.fontSize": 9,
    "mainSeriesProperties.areaStyle.color1":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.areaStyle.color2":
      theme_ === "dark" ? "#000" : "#f7f7f9",
    "mainSeriesProperties.areaStyle.linecolor":
      theme_ === "dark" ? "#03E4BA" : "#003be5",
    "mainSeriesProperties.areaStyle.transparency": 70,
  };
};
