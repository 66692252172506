import { BehaviorSubject } from "rxjs";

class ChartReadyService {
  private chartIsReady = new BehaviorSubject<boolean>(false);
  private changingSymbols = new BehaviorSubject<boolean>(false);

  public subToChartReady = () => {
    return this.chartIsReady.asObservable();
  };
  public setChartisReady = (value: boolean) => {
    this.chartIsReady.next(value);
  };
  public getChartIsReady = () => {
    return this.chartIsReady.value;
  };

  public subToChangingSymbols = () => {
    return this.changingSymbols.asObservable();
  };
  public setChangingSymbols = (value: boolean) => {
    this.changingSymbols.next(value);
  };
  public getChangingSymbols = () => {
    return this.changingSymbols.value;
  };
}

export const _chartReadyService = new ChartReadyService();
